import "./Projects.styles.scss"


const Projects = () => <div className="meetings-container">
    <h2>Pomysły na dalsze działania: </h2>

    <div className="meetings-box">

    </div>

</div>;

export default Projects;
